<script>
import demo from '../LotNews/index'
// import { fakeTrick } from '@/constants/fakeTrick'
// import { visit } from '@/api/client/visit'
// import { contentLimit } from '@/utils/contentLimit'

export default {
  ...demo,
  // computed: {
  //   news: {
  //     get () {
  //       return contentLimit(this.content)
  //     }
  //   }
  // },
  data () {
    return {
      pagetype: 'trick',
      visitQuery: {
        to: '',
        uuid: '',
        id: ''
      }
    }
  },
  // methods: {
  //   updateDetail () {
  //     this.$emit('updateDetail')
  //   },
  //   sendVisit (id) {
  //     this.updateDetail()
  //     this.visitQuery.to = this.pagetype === 'news' ? 'news' : 'winskill'
  //     this.visitQuery.id = id
  //     visit(this.visitQuery)
  //   }
  // }
}
</script>
