import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

// 留言
export function postComment (data) {
  return request({
    url: '/api/info/comments',
    method: 'post',
    data: getFormData(data)
  })
}

// 檢舉留言
export function reportComment (id) {
  return request({
    url: `/api/info/comments/${id}/report`,
    method: 'post'
  })
}

// 取得彩票資訊留言列表
export function getCommentList (params) {
  return request({
    url: `/api/info/comments`,
    method: 'get',
    params: params
  })
}

// 彩票資訊留言按讚
export function getCommentLike (id) {
  return request({
    url: `/api/info/comments/${id}/like`,
    method: 'get'
  })
}

// 刪除彩票資訊單一留言
export function deleteComment (id) {
  return request({
    url: `/api/info/comments/${id}`,
    method: 'delete'
  })
}
