<template>
  <div class="comments-list">
    <div 
      class="comment-row"
      v-for="(item, idx) in commentList"
      :key="idx">
      <div class="comment-avator">
        <el-avatar
          v-if="item.photo && item.photo !==''"
          fit="cover"
          :size="35"
          :src="item.photo">
        </el-avatar>
        <el-avatar 
          v-else 
          :size="35"
          icon="el-icon-user-solid">
        </el-avatar>
      </div>
      <div class="comment-info">
        <div class="user-name">
          <h4>{{item.nickname}}</h4>
          <div 
            class="like-box"
            :class="item.has_like ? 'has-like' : ''">
            <div>{{item.likes}}</div>
            <div 
              class="like-button"
              @click="sendLike(item.id, $event)"></div>
          </div>
        </div>
        <div class="comment-content">{{item.content}}</div>
        <div class="comment-bottom">
          <!-- <div class="reply-button">回复</div> -->
          <div class="comment-time">{{calculateDate(item.created_at)}}</div>
          <div 
            v-if="item.username === myUserName" 
            class="comment-func-button delete-button"
            @click="handleDeleteComment(item.id, idx)">删除</div>
          <div 
            class="comment-func-button report-button"
            @click="handleReportComment(item.id)">举报</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { getCommentLike, deleteComment, reportComment } from '@/api/client/comments'
import { isMemberLogin } from '@/utils/member'

export default {
  props: ['list'],
  data () {
    return {
      commentList: []
    }
  },
  computed: {
    myUserName () {
      return this.$attrs.login.data ? this.$attrs.login.data.username : ''
    }
  },
  methods: {
    handleLogin () {
      this.$message.error("尚未登入，请先登入会员！")
    },
    sendLike (id, event) {
      let el = event.target, parent = event.target.parentElement
      let isLogin = isMemberLogin()
      if ( !isLogin ) {
        return this.handleLogin()
      }
      getCommentLike (id)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (parent.classList.contains("has-like") || el.classList.contains("like-act")) {
              el.classList.remove("like-act")
              parent.classList.remove("has-like")
              parent.children[0].innerText = parseInt(parent.children[0].innerText) - 1
            } else {
              el.classList.add("like-act")
              parent.classList.add("has-like")
              parent.children[0].innerText = parseInt(parent.children[0].innerText) + 1
            }
          } else {
            this.msg = res.message ? res.message : '發生错误'
            this.$message.error(res.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('發生错误')
        })
    },
    calculateDate (date) {
      const ONE_DAY = 1000 * 60 * 60 * 24
      const ONE_HOUR = 1000 * 60 * 60
      const ONE_MIN = 1000 * 60
      const ONE_SEC = 1000
      const fromDate = new Date(date.replace(/\s/, 'T'))
      const nowDate = new Date()
      let diff = nowDate.getTime() - fromDate.getTime()
      diff = diff < 0 ? 0 : diff
      return diff > ONE_DAY ? Math.floor(diff/ONE_DAY) + '天前' :
             diff > ONE_HOUR ? Math.floor(diff/ONE_HOUR) + '小时前' :
             diff > ONE_MIN ? Math.floor(diff/ONE_MIN) + '分钟前' :
             Math.floor(diff/ONE_SEC) + '秒前'
    },
    handleDeleteComment (id, idx) {
      let isLogin = isMemberLogin()
      if ( !isLogin ) {
        return this.handleLogin()
      }
      deleteComment(id).then(res => {
        if (res.status === resStatus.OK) {
          this.commentList.splice(idx, 1)
          this.$emit('updateCommentCount', -1)
          this.$message.success('留言已删除')
        } else {
          this.$message.success(res.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('發生错误')
      })
    },
    handleReportComment (id) {
      let isLogin = isMemberLogin()
      if ( !isLogin ) {
        return this.handleLogin()
      }
      reportComment(id).then(res => {
        if (res.status === resStatus.OK) {
          this.$message.success('投诉已成功提出')
        } else {
          this.$message.success(res.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('發生错误')
      })
    }
  },
  watch: {
    list (cur, prev) {
      this.commentList = cur
    }
  }
}
</script>

<style lang="scss">
.el-avatar>img{width: inherit;}
</style>

<style lang="scss" scoped>
.comments-list {
  margin-top: 20px;
  cursor: default;
  .comment-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;
    .comment-avator {margin-right: 10px;}
    .comment-info {
      width: 100%;
      .user-name {
        display: flex;
        width: 100%;
        justify-content: space-between;
        h4 {
          font-size: 15px;
          font-weight: 500;
          line-height: 25px;
          color: #4F4F4F;
          margin-bottom: 5px;
        }
        .like-box {
          & > div {
            display: inline-block;
            &:first-child {
              font-size: 14px;
              line-height: 17px;
              vertical-align: text-bottom;
            }  
          }
          color: #4F4F4F;
          .like-button {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            background-image: url('~@/assets/thumb-up.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
            &:hover, &.like-act {
              background-image: url('~@/assets/thumb-up-act.png');
            }
            &.like-act {
              animation: liked .2s cubic-bezier(0.075, 0.82, 0.165, 1);
              @keyframes liked {
                0% {transform: scale(0.9);}
                50% {transform: scale(1.15);}
                100% {transform: scale(1);}
              }
            }
          }
          &.has-like {
            color: #005FDE;
            .like-button {background-image: url('~@/assets/thumb-up-act.png');}
          }
        }
      }
      .comment-content {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #4F4F4F;
        margin-bottom: 5px;
        word-break: break-word;
      }
      .comment-bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        line-height: 25px;
        text-align: left;
        color: #4F4F4F;
        .comment-time {
          color: #828282;
          margin-right: 10px;
        }
        .comment-func-button {
          display: inline-block;
          cursor: pointer;
          margin-right: 10px;
          &:hover {color: #005FDE;}
        }
      }
    }
    &:hover {
      .comment-info {
        .comment-bottom {
          .report-button{display: inline-block;}
        }
      }
    }
  }
}
</style>