<template>
  <div class="info-container">
    <div class="header">
      <div class="left">
        <h2>为您推荐</h2>
      </div>
    </div>
    <div class="content">
      <!-- <transition name="lot-info"> -->
        <keep-alive>
          <component
            :is="curTab"
            :content="content"
            @updateDetail="updateDetail" />
        </keep-alive>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { lotInfoTabs } from '@/constants/home'
import LotNews from './LotNews'
import LotTrick from './LotTrick'
import { getNewsList } from '@/api/client/lotNews'
import { getTricksList } from '@/api/client/lotTricks'
import resStatus from '@/constants/resStatus'

export default {
  name: 'lotInfo',
  props: ['show'],
  components: {
    LotNews,
    LotTrick
  },
  data () {
    return {
      curTab: this.checkShowTab(),
      tabs: lotInfoTabs,
      query: {
        page: 1,
        pageSize: 5
      },
      content: []
    }
  },
  methods: {
    handleClickTab (tab) {
      this.curTab = tab.id
    },
    isActive (tab) {
      return tab.id === this.curTab
    },
    checkShowTab () {
      if (this.show === 'lotteryInfoDetail' && this.$route.query.type === 'trick') {
        return 'LotTrick'
      } else {
        return 'LotNews'
      }
    },
    updateDetail () {
      this.$emit('updateDetail')
    },
    updateContent () {
      if (this.curTab === 'LotNews') {
        getNewsList(this.query)
          .then(res => {
            if (res.status === resStatus.OK) {
              this.content = res.data
            }
          })
      } else {
        getTricksList(this.query)
          .then(res => {
            if (res.status === resStatus.OK) {
              this.content = res.data
            }
          })
      }
    }
  },
  created () {
    this.updateContent()
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0 0 30px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 30px 0 0 0;
    .left {
      display: flex;
      align-items: center;
      h2 {
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
        text-align: left;
        color:#4F4F4F;
      }
    }
  }
  .content {
    flex-grow: 99;
  }
}
@media (max-width: 575.98px) {
  .info-container {
    padding: 0 15px;
    .header {
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .info-container {
    padding: 0 15px;
    .header {
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .info-container {
    padding: 0 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .info-container {
    min-height: 484px;
  }
}
</style>
