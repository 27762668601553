<template>
  <div class="lot-news-container">
    <ul class="lot-news-list">
      <li
        v-for="(item, idx) in news"
        :key="idx + item.showdate">
        <router-link :to="{ name: 'LotteryInfoDetail', query: { id: item.id,type: pagetype,num: 5 }}"
        @click.native="sendVisit(item.id)">
          <div
            class="image-container"
            :style="{'background-image' : `url('${getPhotoUrl(item)}')`}"></div>
        </router-link>
        <div class="content-container" >
          <router-link class="detail-recommend-title" :to="{ name: 'LotteryInfoDetail', query: { id: item.id,type: pagetype,num: 5 }}"
          @click.native="sendVisit(item.id)">{{ item.title }}</router-link>
          <div
            class="content" v-html="item.content">
          </div>
          <div class="content-date">{{ item.showdate }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// import { fakeNews } from '@/constants/fakeNews'
import { visit } from '@/api/client/visit'
import { contentLimit } from '@/utils/contentLimit'

export default {
  name: 'lotNews',
  // 首頁與彩票資訊共用，用props控制呈現模式
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      pagetype: 'news',
      visitQuery: {
        to: '',
        uuid: '',
        id: ''
      }
    }
  },
  computed: {
    news: {
      get () {
        return contentLimit(this.content)
      }
    }
  },
  methods: {
    updateDetail () {
      this.$emit('updateDetail')
    },
    sendVisit (id) {
      this.updateDetail()
      this.visitQuery.to = this.pagetype === 'trick' ? 'winskill' : this.pagetype
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getPhotoUrl (item) {
      return item.photo !== '' ? item.photo : item.photo_u !== "" ? item.photo_u : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-news-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > :not(:first-child) {
    border-top: 1px solid #E0E0E0;
  }
  li {
    display: flex;
    height: 150px;
    min-height: 150px;
    box-sizing: border-box;
    justify-content: center;
    align-items: stretch;
    padding: 20px 0;
    &:hover {
      cursor: pointer;
      & .content-container .detail-recommend-title {
        color: #005FDE;
      }
    }
    // gap: 25px;
    .image-container{
      width: 135px;
      height: 110px;
      border-radius: 5px;
      background-position: center;
      background-size: cover;
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 40px - 135px - 25px);
      flex-grow: 99;
      text-align: left;
      margin-left: 25px;
      height: 100%;
      box-sizing: border-box;
      .detail-recommend-title {
        display: block;
        color: #333333;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
      }
      .detail-recommend-title:hover {
        color: #005FDE;
      }
      .content {
        color: #000000;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        font-style: normal;
        text-align: left;
        margin: 10px 0px 10px 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-word;
        overflow: hidden;
        .img {
          max-width: 100%;
        }
      }
      .content-date {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #BDBDBD;
      }
    }
  }
  &:first-child {border-top: none;}
}
@media (max-width: 575.98px) {
  .lot-news-list {
    li {
      min-height: 100px;
      height: 100px;
      padding: 15px 0;
      .image-container{
        width: 100px;
        height: 70px;
        border-radius: 3px;
      }
      .content-container {
        width: calc(100% - 30px - 135px - 13px);
        margin-left: 13px;
        .detail-recommend-title {
          display: -webkit-box;
          font-size: 15px;
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; 
          white-space: normal;
        }
        .content {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-news-list {
    li {
      min-height: 100px;
      height: 100px;
      padding: 15px 0;
      .image-container{
        width: 100px;
        height: 70px;
        border-radius: 3px;
      }
      .content-container {
        width: calc(100% - 30px - 135px - 13px);
        margin-left: 13px;
        .detail-recommend-title {
          display: -webkit-box;
          font-size: 15px;
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; 
          white-space: normal;
        }
        .content {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-news-list {
    li {
      .content-container {
        width: calc(100% - 40px - 135px - 20px);
        margin-left: 20px;
        .content {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; 
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-news-list {
    li {
      .content-container {
        width: calc(100% - 40px - 135px - 20px);
        margin-left: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {}
</style>
