<template>
  <div class="home-container">
    <div class="info-tool-container">
      <div class="left-container">
        <lotteryInfoDetail
          :detail="detail"
          @updateCount="updateCount"
          @scrollToComment="scrollToComment"
          v-bind="$attrs" />
        <hr v-if="showHr && showpromoteImage">
        <advertise
          v-if="showHr && showpromoteImage"
          class="left-promote-container"
          :ids="ids"
          @needShowHr="needShowHr" />
        <hr v-if="showComment">
        <lot-comment
          v-if="showComment"
          v-bind="$attrs"
          ref="commentBlock"
          :commentSize="detail.comments"
          :scrollTo="scrollToComment"
          @updateCount="updateCount"
          class="lot-comment" />
        <hr>
        <lot-info 
          class="lot-info" 
          show='lotteryInfoDetail'
          @updateDetail="getDetailData"/>
      </div>
      <div class="right-container">
        <div class="lot-tool-box">
          <lot-tool class="lot-tool" />
        </div>
        <lot-predict />
        <advertise :ids="ids"/>
      </div>
    </div>
  </div>
</template>

<script>
import LotTool from '@/components/LotteryInfo/LotTool'
import LotInfo from './lotInfo'
import LotComment from './lotComment'
import lotPredict from '@/components/LotteryInfo/LotteryPredict'
import advertise from '@/components/LotteryInfo/advertise'
import lotteryInfoDetail from '@/components/LotteryInfo/LotteryInfoDetail'
import { getNews } from '@/api/client/lotNews'
import { getTrick } from '@/api/client/lotTricks'
import { getArticle } from '@/api/client/lotShare'
import resStatus from '@/constants/resStatus'

export default {
  name: 'LotteryInfo',
  components: {
    LotTool,
    LotInfo,
    LotComment,
    lotPredict,
    advertise,
    lotteryInfoDetail
  },
  data () {
    return {
      detail: {},
      ids: '6,7,8',
      showHr: true
    }
  },
  created () {
    this.getDetailData()
  },
  computed: {
    showpromoteImage () {
      return window.innerWidth < 992
    },
    showComment () {
      return this.$route.query.type === 'user_article'
    }
  },
  methods: {
    getDetailData () {
      let getFunc = this.$route.query.type === 'news' ? getNews :
                    this.$route.query.type === 'trick' ? getTrick : 
                    getArticle
      getFunc(this.$route.query.id)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.detail = res.data
          }
        })
    },
    needShowHr (boo) {
      this.showHr = boo
    },
    scrollToComment () {
      this.$refs.commentBlock.scrollTo()
    },
    updateCount (count) {
      this.detail.comments = count
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px 0;
  // gap: 20px;
  box-sizing: border-box;
  .info-tool-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    // gap: 20px;
    .left-container{
      width: calc((100% - 20px) * 0.66);
      background-color: #FFF;
      padding: 10px 30px 4px;
      border-radius: 5px;
      margin-right: 20px;
      .lot-info {
        width: 100%;
      }
    }
    .right-container {
      width: calc((100% - 20px) * 0.34);
      .lot-tool-box {
        display: flex;
        min-height: 484px;
      }
      .ad-container {
        margin-top: 20px;
        width: 100%;
        img {
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .home-container {
    .info-tool-container {
      height: fit-content;
      .left-container{
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin-right: 0;
        .left-promote-container{
          padding: 30px 40px;
          // background-color: #F3F9FF;
        }
        .lot-info {
          width: 100%;
        }
      }
      .right-container {display: none;}
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .home-container {
    .info-tool-container {
      height: fit-content;
      .left-container{
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin-right: 0;
        .left-promote-container{
          padding: 30px 40px;
          // background-color: #F3F9FF;
        }
        .lot-info {
          width: 100%;
        }
      }
      .right-container {display: none;}
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .home-container {
    padding: 0;
    .info-tool-container {
      .left-container {
        width: 100%;
        margin-right: 0;
        padding: 0;
        .left-promote-container{
          padding: 30px;
          background-color: #FFF;
        }
        .lot-info {
          width: 100%;
        }
      }
      .right-container {display: none;}
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .home-container {padding: 0;}
}
@media (min-width: 1200px) {
  .home-container {
    .info-tool-container {
      .left-container {width: 792px;}
      .right-container {width: 386px;}
    }
  }
}
</style>
