<template>
  <div id="commentBlock" class="comment-container">
    <div class="header">
      <div class="left">
        <h2>评论 {{count}}</h2>
      </div>
    </div>
    <div class="apply-comment-block">
      <div class="comment-avator">
        <el-avatar 
          :size="35"
          v-if="this.$attrs.login.isLogin && this.$attrs.login.data.photo !== ''" 
          :src="this.$attrs.login.data.photo">
        </el-avatar>
        <el-avatar
          :size="35"
          v-else 
          icon="el-icon-user-solid">
        </el-avatar>
      </div>
      <div v-if="this.$attrs.login.isLogin" class="comment-input">
        <el-input
          type="textarea"
          placeholder="说些什么吧~"
          v-model="query.content">
        </el-input>
        <div 
          class="comment-submit"
          @click="submitComment">
          评论
        </div>
      </div>
      <div v-else class="comment-input">
        <p>请先登入后再来发表评论~</p>
        <div 
          class="comment-login"
          @click="handleLogin">
          登入
        </div>
      </div>
    </div>
    <comment-list 
      v-bind="$attrs"
      :list="comments"
      @updateCommentCount="updateCommentCount" />
    <div
      v-if="showMoreBtn"
      class="more-comment-button"
      @click="moreCommentList">
      <span v-if="!loadMore">
        查看更多评论 <i class="el-icon-arrow-down"></i>
      </span>
      <span v-else class="loading-icon">
        <i class="el-icon-loading"></i>
      </span>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import commentList from '@/components/LotteryInfo/comments'
import { postComment, getCommentList } from '@/api/client/comments'

export default {
  components: {
    commentList
  },
  props: {
    commentSize: {
      default: 0
    }
  },
  data () {
    return {
      comments: [],
      count: this.commentSize,
      query: {
        nid: '',
        content: ''
      },
      params: {
        nid: '',
        cid: '',
        size: 10
      },
      showMoreBtn: true,
      loadMore: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.commentSize < 10) {
        this.showMoreBtn = false
      }
      this.query.nid = this.$route.query.id
      this.params.nid = this.$route.query.id
      this.handleCommentList()
    },
    handleCommentList () {
      getCommentList (this.params)
        .then(res => {
          this.loadMore = false
          this.showMoreBtn = false
          if (res.status === resStatus.OK) {
            if (res.data.length > 0) {
              this.comments = this.comments.concat(res.data)
              this.params.cid = this.comments[this.comments.length - 1].id
            }
            if (this.comments.length < this.count || res.data.length >= 10 ) {
              this.showMoreBtn = true
            }
          }
        }).catch(err => {
          console.log(err)
          // this.$message.error('资料读取失败')
        })
    },
    moreCommentList () {
      this.loadMore = true
      this.handleCommentList()
    },
    updateCommentList (data) {
      this.count = parseInt(this.count) + 1
      this.$emit('updateCount', this.count)
      this.comments.unshift(data)
    },
    clearCommentArea () {
      this.query.content = ''
    },
    submitComment () {
      postComment(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.$message.success('發布成功')
            this.clearCommentArea()
            this.updateCommentList(res.data)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('發生错误，请稍后再试')
        })
    },
    handleLogin () {
      this.$attrs.login.formType = 'SignIn'
    },
    scrollTo () {
      let el = document.querySelector("#commentBlock")
      if (el) {
        el.scrollIntoView({behavior: 'smooth'})
      }
    },
    updateCommentCount (num) {
      this.count = this.count + num
      this.$emit('updateCount', this.count)
    }
  },
  watch: {
    commentSize (size) {
      this.count = size
    }
  }
}
</script>

<style lang="scss">
.el-avatar>img{width: inherit;}
</style>

<style lang="scss" scoped>
.comment-container {
  padding: 30px 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 0 18px 0;
    .left {
      display: flex;
      align-items: center;
      h2 {
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 29px;
        text-align: left;
        color:#4F4F4F;
      }
    }
  }
  .apply-comment-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    .comment-avator {
      margin-right: 10px;
    }
    .comment-input {
      flex: 1;
      background: #F2F2F2;
      border-radius: 8px;
      padding: 15px;
      text-align: center;
      cursor: default;
      p {
        font-size: 13px;
        line-height: 25px;
        color: #4F4F4F;
      }
      .comment-submit, .comment-login {
        width: 60px;
        height: 25px;
        line-height: 25px;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: #FFFFFF;
        background: #2F84ED;
        border-radius: 4px;
        float: right;
        cursor: pointer;
        &:hover {background: #005FDE;}
      }
      .comment-login {
        float: unset;
        margin: 10px auto 3px;
      }
    }
  }
  .more-comment-button {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    padding: 8px;
    margin-top: 25px;
    background: #F2F2F2;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: #E0F7FF;
      // color: #FFFFFF;
    }
    & i {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    .loading-icon {
      & i {
        font-size: 20px;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .comment-container {
    padding: 25px 15px;
    .header {
      margin: 0 0 20px 0;
      .left {
        h2 {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .apply-comment-block {
      margin-bottom: 15px;
      .comment-input {
        border-radius: 5px;
        padding: 12px;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .comment-container {
    padding: 25px 15px;
    .header {
      margin: 0 0 20px 0;
      .left {
        h2 {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .apply-comment-block {
      margin-bottom: 15px;
      .comment-input {
        border-radius: 5px;
        padding: 12px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .comment-container {
    padding: 25px 30px;
    .header {
      margin: 0 0 20px 0;
      .left {
        h2 {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .apply-comment-block {
      margin-bottom: 15px;
      .comment-input {
        border-radius: 5px;
        padding: 12px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
<style lang="scss">
.comment-input {
  .el-textarea {
    .el-textarea__inner {
      resize: none;
      color: #4F4F4F;
      border: none;
      background: transparent;
      padding: 0;
    }
  }
}
</style>