<template>
  <div class="lottery-detail-container">
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
    <div class="lottery-detail-content">
      <h1>{{ getData.title }}</h1>
      <div class="content-info">
        <div class="left-container">
          <span class="date">{{ createDate }}</span>
          <span class="author" v-if="detail.type !== 'news'">
            责编: {{ getData.type === 'user_article' ? getData.nickname : getData.author }}
          </span>
        </div>
        <div class="right-container">
          <span class="clicks" v-if="getData.type !== 'news'">点击: {{ getData.clicks }}</span>
          <span class="source">来源: {{ getData.source }}</span>
        </div>
      </div>
      <div class="article" v-html="getData.content"></div>
      <div class="buttons" v-if="getData.type === 'user_article'">
        <div class="button-box">
          <div
            class="content-func-btn like"
            :class="getData.has_like ? 'btn-active' : ''"
            @click="handleLike">
            {{likeBtn}}
          </div>
          <span>{{getData.likes}}</span>
        </div>
        <div class="button-box">
          <div
            class="content-func-btn comment"
            @click="scrollToComment">评论</div>
          <span>{{getData.comments}}</span>
        </div>
        <div class="button-box">
          <div
            class="content-func-btn report"
            @click="handleReportArticle">举报</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { sendLike, reportUserArticle } from '@/api/client/lotShare'

export default {
  name: 'lotteryInfoDetail',
  props: ['detail'],
  data () {
    return {
      nid: '',
      info: this.detail,
      likeBtn: '点赞',
    }
  },
  created () {
    this.nid = this.$route.query.id
  },
  mounted () {
    this.likeBtn = this.detail.has_like ? '收回赞' : '点赞'
  },
  computed: {
    breadcrumb: {
      get () {
        return [
          {
            text: '首页',
            to: { name: 'Home' }
          },
          {
            text: '彩票资讯',
            to: { name: 'LotteryInfo' }
          },
          {
            text: this.detail.title,
            active: true
          }
        ]
      }
    },
    createDate: {
      get () {
        return this.detail.start_at ? this.detail.start_at : 
               this.detail.created_at ? this.detail.created_at : ''
      }
    },
    getData () {
      return this.info && this.info !== undefined ? this.info : this.detail
    }
  },
  methods: {
    handleLike (event) {
      let el = event.target, parent = event.target.parentElement
      if (this.$attrs.login.isLogin) {
        sendLike(this.nid)
        .then(res => {
          if (res.status === resStatus.OK) {
            if (el.classList.contains("btn-active")) {
              this.$message.success("已收回赞")
              el.classList.remove("btn-active")
              parent.children[1].innerText = parseInt(parent.children[1].innerText) - 1
              this.likeBtn = "点赞"
            } else {
              this.$message.success("已点赞")
              el.classList.add("btn-active")
              parent.children[1].innerText = parseInt(parent.children[1].innerText) + 1
              this.likeBtn = "收回赞"
            }
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error("發生错误，请稍后再试！")
        })
      } else {
        // this.$attrs.login.formType = "SignIn"
        this.$message.error("尚未登入，请先登入会员！")
      }
    },
    scrollToComment() {
      this.$emit('scrollToComment', 'commentBlock')
    },
    handleReportArticle () {
      reportUserArticle(this.nid).then(res => {
        if (res.status === resStatus.OK) {
          this.$message.success("投诉已成功提出")
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error("發生错误，请稍后再试！")
      })
    }
  },
  watch: {
    detail(cur) {
      this.info = cur
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-detail-container {
  text-align: left;
  background-color:white;
  display: flex;
  flex-direction: column;
  padding: 5px 0 25px;
  cursor: default;
  .breadcrumb {
    background-color: #FFFFFF;
    padding: 18px 0 12px;
    margin-bottom: 0;
    .breadcrumb-item + .breadcrumb-item::before {
      content: '>';
      color: #828282;
    }
    .active {
      color: #828282;
      & > span {
        max-width: 30em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
  .lottery-detail-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    h1 {
      font-weight: 500;
      font-size: 28px;
      line-height: 39px;
      color: #333333;
      margin-bottom: 15px;
    }
    .article {
      max-width: 100%;
      word-break: break-word;
    }
    .content-info{
      display: flex;
      color: #4F4F4F;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 15px;
      .date {color: #828282;}
      .source {padding-right: 22px;}
      .left-container, .right-container {
        width: 50%;
        & span {
          display: inline-block;
          padding-right: 13px;
        }
      }
      .right-container {text-align: right;}
    }
    .buttons {
      text-align: right;
      margin: 40px 0 30px;
      .button-box {
        position: relative;
        display: inline-block;
        width: fit-content;
        margin-right: 45px;
        span {
          min-width: 2.5em;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: left;
        }
        &:last-child {margin-right: 0;}
        .content-func-btn {
          display: inline-block;
          color: #4F4F4F;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          padding: 6px 10px 5px 24px;
          background: #FFFFFF;
          border: 1px solid #C4C4C4;
          border-radius: 3px;
          background-size: 14px 14px;
          background-position: 6px center;
          background-repeat: no-repeat;
          margin-right: 5px;
          cursor: pointer;
          &:hover, &.btn-active {
            color: #FFFFFF;
            background: #005FDE;
            border: 1px solid #005FDE;
          }
          &.like, &.comment, &.report {
            &:hover, &.btn-active {
              background-size: 14px 14px;
              background-position: 6px center;
              background-repeat: no-repeat;
            }
          }
          &.like, &.btn-active {
            background-image: url('~@/assets/thumb-up-fill-gray.png');
            &.btn-active, &:hover {
              background-image: url('~@/assets/thumb-up-fill-white.png');
            }
          }
          &.comment {
            background-image: url('~@/assets/comment-fill-gray.png');
            &:hover {
              background-image: url('~@/assets/comment-fill-white.png');
            }
          }
          &.report {
            background-image: url('~@/assets/report-gray.png');
            &:hover {
              background-image: url('~@/assets/report-white.png');
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .lottery-detail-container {
    padding: 0 15px 25px;
    .breadcrumb {display: none;}
    .lottery-detail-content {
      h1 {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      .content-info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 20px;
        & > span {
          display: block;
          font-size: 13px;
          line-height: 16px;
          padding-right: 0;
        }
        .date {padding-right: 10px;}
        .author {
          flex: 1 1 50%;
          padding-right: 6px;
        }
        .clicks {
          text-align: right;
          padding-right: 0;
        }
        .source {
          padding-right: 10px;
          flex-grow: 3;
          flex-shrink: 1;
        }
      }
      .buttons {
        margin: 30px 0 10px;
        text-align: center;
        flex-grow: 2;
        flex-shrink: 1;
        .button-box {
          span {padding-right: 0;}
          &:first-child {
            span {
              min-width: 2.5em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lottery-detail-container {
    padding: 0 15px 25px;
    .breadcrumb {display: none;}
    .lottery-detail-content {
      h1 {
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      .content-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        & > span {
          display: block;
          font-size: 13px;
          line-height: 16px;
          padding-right: 0;
        }
        .date {padding-right: 10px;}
        .author {
          flex: 1 1 50%;
          padding-right: 6px;
        }
        .clicks {
          text-align: right;
          padding-right: 0;
        }
        .source {
          padding-right: 10px;
          margin-top: 13px;
          flex-grow: 3;
          flex-shrink: 1;
        }
      }
      .buttons {
        margin: 30px 0 10px;
        text-align: center;
        flex-grow: 2;
        flex-shrink: 1;
        .button-box {
          span {padding-right: 0;}
          &:first-child {
            span {
              min-width: 2.5em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lottery-detail-container {
    padding: 15px 25px;
    border-radius: 5px;
    .lottery-detail-content {
      h1 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
      }
      .content-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > span {
          display: block;
          padding-right: 0;
        }
        .date {padding-right: 10px;}
        .author {
          flex: 1 1 50%;
          padding-right: 6px;
        }
        .clicks {
          text-align: right;
          padding-right: 0;
        }
        .source {
          padding-right: 10px;
          margin-top: 13px;
          flex-grow: 3;
          flex-shrink: 1;
          flex-basis: 70%;
        }
      }
      .buttons {
        margin-top: 10px;
        text-align: right;
        flex-grow: 2;
        flex-shrink: 1;
        .button-box {
          span {padding-right: 0;}
          &:first-child {
            span {
              min-width: 1.5em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lottery-detail-container {
    .lottery-detail-content {
      .content-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > span {
          display: block;
          padding-right: 0;
        }
        .date {padding-right: 10px;}
        .author {
          flex: 1 1 50%;
          padding-right: 6px;
        }
        .clicks {
          text-align: right;
          padding-right: 0;
        }
        .source {
          padding-right: 10px;
          margin-top: 10px;
          flex-grow: 3;
          flex-shrink: 1;
          flex-basis: 70%;
        }
      }
      .buttons {
        margin-top: 10px;
        text-align: right;
        flex-grow: 2;
        flex-shrink: 1;
        .button-box {
          span {padding-right: 0;}
          &:first-child {
            span {
              min-width: 1.5em;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
}
</style>
<style lang="scss">
.lottery-detail-container{
  .article{
    font-size: 1.25em;
    line-height: 1.67;
    img {max-width: 100%;}
    p{
      margin: 0.5em 0;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .article {font-size: 1.16em;}
  }
}
</style>
